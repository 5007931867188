import WidgetIcon from '@material-ui/icons/Widgets';
import TemplateList from './TemplateList';
import { ShowTemplate } from './ShowTemplate';
import { CreateTemplate } from './CreateTemplate';
import { EditTemplate } from './EditTemplate';

export default {
      list: TemplateList,
      show: ShowTemplate,
      create: CreateTemplate,
      edit: EditTemplate,
      icon: WidgetIcon,
}
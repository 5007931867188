// in src/users.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, 
         Datagrid, 
         TextField, 
         ReferenceField,
         DeleteButton,
         EditButton,
         Filter,
         ReferenceInput,
         SelectInput,
         ShowButton } from 'react-admin';

import { CurrencyNumberField } from '../components/CurrencyNumberField'

const styles = {
    deleteButton: {
        withWidth: '20',
    },
    editButton: {
        withWidth: '20',
    },
    showButton: {
        withWidth: '20',
    }
};
        
const TemplatePriceFilter = ({ permissions, ...props }) =>
    <Filter {...props}>
        <ReferenceInput label="Template" source="template_id" reference="templates">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Finish" source="material" reference="materials">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Size" source="canvas_size" reference="canvas_sizes">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>;

const TemplatePriceList = ({ classes, ...props }) => (
    <List title="Template Size Prices" {...props} perPage={25} filters={<TemplatePriceFilter />}>
        <Datagrid>
            <TextField source="id" />

            <ReferenceField label="Template" source="template_id" reference="templates" link="show" allowEmpty>
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField label="Canvas Sizes" source="canvas_size" reference="canvas-sizes" link="show" allowEmpty>
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField label="Finish" source="material" reference="materials" link="false" allowEmpty>
                <TextField source="name" />
            </ReferenceField>

            <CurrencyNumberField source="price" options={{ style: 'currency' }} />
            <CurrencyNumberField source="gift_wrap" options={{ style: 'currency' }}/>

            <ShowButton label="" className={classes.showButton}/>
            <EditButton label="" className={classes.editButton}/>
            <DeleteButton label="" className={classes.deleteButton}/>
        </Datagrid>
    </List>
);

export default withStyles(styles)(TemplatePriceList);
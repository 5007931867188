// in src/users.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, 
         Datagrid, 
         TextField, 
         ReferenceField,
         DeleteButton,
         ShowButton,
         RichTextField } from 'react-admin';

const styles = {
    deleteButton: {
        minWidth: '40px',
        align: 'right'
    }
};
        
const TextList = ({ classes, ...props }) => (
    <List title="Texts" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="frame" />
            <TextField source="font_name" />
            <ReferenceField label="Canvas" source="canvasId" reference="canvases" allowEmpty>
                <TextField source="id" />
            </ReferenceField>
            <RichTextField source="plain" label="Plain Text" />
            <DeleteButton label="" className={classes.deleteButton}/>
            <ShowButton label="" />
        </Datagrid>
    </List>
);

export default withStyles(styles)(TextList);
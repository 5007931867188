import React from 'react';

import { Edit,
         ReferenceInput,
         SelectInput,
         TextInput,
         SimpleForm } from 'react-admin';

//import PrintShippingLabelButton from '../components/PrintLabelButton';

const optionRenderer = choice => `${choice.carrier} - ${choice.label}`;

export const EditOrder = (props) => (
    <Edit {...props}>
        <SimpleForm > 
            <ReferenceInput label="Order Status" source="status" reference="orderstatuses" sort={{ field: 'id', order: 'ASC' }}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput source="trackingNumber" allowEmpty/>

            <ReferenceInput label="Shipping Method" source="shipping_method_id" reference="shipping" sort={{ field: 'id', order: 'ASC' }} allowEmpty >
                <SelectInput optionText={optionRenderer} />
            </ReferenceInput>

            {/* <PrintShippingLabelButton label="Print Label" sortable={false} printing={false} elStyle={{ display: 'block' }} /> */}
        </SimpleForm>
    </Edit>
);
import Icon from '@material-ui/icons/Folder';
import ProductTypeList from './ProductTypeList';
import { EditProductType } from './EditProductType';
import { CreateProductType } from './CreateProductType';

export default {
      list: ProductTypeList,
      icon: Icon,
      edit: EditProductType,
      create: CreateProductType
}
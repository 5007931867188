import CustomCanvasLayerIcon from '@material-ui/icons/CropOriginal';

import CustomCanvasPriceList from './CustomCanvasPriceList';

import EditCustomCanvasSizePrice from './EditCustomCanvasSizePrice';
import CreateCustomCanvasSizePrice from './CreateCustomCanvasSizePrice';

export default {
      create: CreateCustomCanvasSizePrice,
      list: CustomCanvasPriceList,
      edit: EditCustomCanvasSizePrice,
      icon: CustomCanvasLayerIcon,
}
// in src/users.js
import React from 'react';
import { List, 
         Datagrid, 
         EmailField,
         TextField, 
         BooleanField,
         ReferenceField,
         DateField,
         EditButton,
         DeleteButton
         } from 'react-admin';

import ApprovalDeliveredField from '../components/ApprovalDeliveredField'

const ApprovalList = ({ classes, ...props }) => (
    <List title="Order Approvals" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField label="Order" source="order_id" reference="orders">
                <TextField source="id" style={{ textAlign: 'center' }} />
            </ReferenceField>    
            <ReferenceField label="Canvas" source="canvas_id" reference="canvases">
                <TextField source="id" style={{ textAlign: 'center' }} />
            </ReferenceField>   
            <EmailField source="email" />
            <BooleanField source="approved" label="Approved" />
            <TextField source="notes" label="Notes" />
            <DateField source="date_sent" showTime />
            <ApprovalDeliveredField source="email_sent" label="Email Delivered" />
            <ApprovalDeliveredField source="ios_push_sent" label="iOS Push" />
            <EditButton label=""/>
            <DeleteButton label=""/>
        </Datagrid>
    </List>
);

export default (ApprovalList);
import React, { Component } from 'react';
import './App.css';

import { Admin, Resource } from 'react-admin';
import authProvider from './utils/authProvider';

import { Layout, Menu } from './layout';

import customRoutes from './routes';

import orders from './orders';
import approvals from './approvals';
import payments from './payments';
import addresses from './addresses';
import canvases from './canvases';
import canvas_background_textures from './canvas_background_textures';
import images from './images';
import texts from './texts';
import templates from './templates';
import templateCategories from './template-categories';
import templatePrices from './template-prices';
import steps from './steps';
import product_type from './product_type';
import product_type_option from './product_type_option';
import product_type_option_item from './product_type_option_item';

import customCanvasPrices from './custom-canvas-prices';
import notificationsTemplate from './notification-templates';
import users from './users';
import coupons from './coupons';

import { createMuiTheme } from '@material-ui/core/styles';

import Dashboard from './dashboard/Dashboard';

import dataProvider from './utils/dataProvider';
import addUploadFeature from './utils/addUploadCapabilities';

const uploadCapableClient = addUploadFeature(dataProvider);

const theme = createMuiTheme({
    palette: {
      type: 'light', // Switching the dark mode on is a single property value change.
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    }
  });

class App extends Component {
    state = { dataProvider: null };

    async componentWillMount() {
        this.restoreFetch = uploadCapableClient;
        const dataProvider = uploadCapableClient;
        this.setState({ dataProvider });
    }

    componentWillUnmount() {
        this.restoreFetch();
    }

    render() {
        const { dataProvider } = this.state;

        if (!dataProvider) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            );
        }

        return ( 
            <Admin authProvider={authProvider}
                   dashboard={Dashboard}
                   dataProvider={uploadCapableClient} 
                   title="OC Canvas" 
                   appLayout={Layout}
                   theme={theme} 
                   menu={Menu}
                   customRoutes={customRoutes}
                > 

                {/* <Favicon url="/path/to/favicon.ico"/> */}

                <Resource name="dashboard" />

                <Resource name="orders" {...orders} />
                <Resource name="approvals" {...approvals}/>
                <Resource name="payments" {...payments} />
                <Resource name="address" {...addresses} />

                <Resource name="canvases" {...canvases} />
                <Resource name="canvas_product_options" />
                <Resource name="canvas-meta" />
                <Resource name="canvas_product_option_item" />
                <Resource name="canvas-background" />
                <Resource name="canvas-background-textures" {...canvas_background_textures}/>

                <Resource name="images" {...images} />
                <Resource name="texts" {...texts}/>
                
                <Resource name="templates-all" {...templates}/>
                <Resource name="template-prices" {...templatePrices} />
                <Resource name="steps" {...steps} />

                <Resource name="product_type" {...product_type} />
                <Resource name="product_type_option" {...product_type_option} />
                <Resource name="product_type_option_items" {...product_type_option_item} />
                <Resource name="product_type_option_items_cost"  />

                <Resource name="template-steps" {...steps} />
                <Resource name="template-detail-images"/>

                <Resource name="coupons" {...coupons} />
                {/* <Resource name="materials" {...materials} /> */}
                <Resource name="shipping" />
                <Resource name="orderstatuses" />
                <Resource name="template-categories" {...templateCategories} />
                
                <Resource name="canvas-sizes" />
                <Resource name="generic-canvas-sizes" />
                <Resource name="custom-canvas-prices" {...customCanvasPrices} />
                
                <Resource name="notification-actions"/>
                <Resource name="notification-templates" {...notificationsTemplate} />
                <Resource name="users" {...users} />
            </Admin>
        );
    }
}

export default App;

import React from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';

// import genericDataProvider from '../utils/dataProvider';
// import addUploadFeature from '../utils/addUploadCapabilities';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const myCustomIconStyle = {
    avatar: {
        height: 30,
        width: 30,
    },
};

// dataProvider(GET, 'user/avatar', {
//     filter: { created: aMonthAgo.toISOString() },
//     sort: { field: 'date', order: 'DESC' },
//     pagination: { page: 1, perPage: 50 },
// })
// .then(response => {
//     console.log("Hey, this should be a response: ");
//     console.log(response);

//     return response.data
//     .filter(order => order.status !== '6')
//     .reduce(
//         (stats, order) => {
//             if (order.status !== '6') {
//                 stats.revenue += order.total;
//                 stats.nbNewOrders++;
//             }
//             // if (order.status === 'ordered') {
//             //     stats.pendingOrders.push(order);
//             // }
//             return stats;
//         },
//         {
//             revenue: 0,
//             nbNewOrders: 0,
//         }
//     )
// })

class ProfileIcon extends React.Component {
    
    render() {
        const {classes} = this.props
        var avatarUrl = localStorage.getItem('avatar');
        if (avatarUrl == null) {
            return (
                <AccountCircle/>
            );
        } else {
            return (
                <Avatar className={classes.avatar} src={avatarUrl}>
                </Avatar> 
            );
        }
    };
}

const MyCustomIcon = withStyles(myCustomIconStyle)(ProfileIcon);

// const MyCustomIcon = withStyles(myCustomIconStyle)( ({ classes }) => ( 
//     <div>
//         {localStorage.getItem('avatar') != null ? 
//             <Avatar
//                 className={classes.avatar}
//                 src={localStorage.getItem('avatar') && localStorage.getItem('avatar')}
//             //src={ localStorage.getItem('avatar') != null ? localStorage.getItem('avatar') : '#' }
//             >
//             </Avatar> 
//         :
//             <AccountCircle/>
//         }
//     </div>
// ));

const CustomUserMenu = ({ ...props }) => (
    <UserMenu {...props} icon={<MyCustomIcon />}>
        <MenuItemLink
            to="/configuration"
            primaryText="Settings"
            leftIcon={<SettingsIcon />}
        />
    </UserMenu>
);

const CustomAppBar = ({ classes, ...props }) => (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
        <Typography
            variant="h5"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        <span className={classes.spacer} />
    </AppBar>
);

export default withStyles(styles)(CustomAppBar);

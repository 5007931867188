import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'connected-react-router';
import { CREATE } from 'react-admin';

import dataProvider from '../utils/dataProvider';
import addUploadFeature from '../utils/addUploadCapabilities';
const uploadCapableClient = addUploadFeature(dataProvider);

class UploadAvatarButton extends Component {
      handleClick = () => {
          const { push, showNotification } = this.props;

          const updatedRecord = { 
                image: this.props.preview,
          };

          uploadCapableClient(CREATE, `user/upload-avatar`, { data: updatedRecord })
              .then((response) => {

                  if (response.data.avatar != null) {
                    localStorage.setItem('avatar', response.data.avatar);
                  }

                  showNotification('Avatar uploaded');
                  push('/');
              })
              .catch((e) => {
                  console.error(e);
                  showNotification('Error: avatar not uploaded', 'warning')
              });
      }
  
      render() {
          return <Button variant="raised" label="Approve" onClick={this.handleClick} >Upload</Button>;
      }
  }
  
  UploadAvatarButton.propTypes = {
      push: PropTypes.func,
      showNotification: PropTypes.func,
      preview: PropTypes.object,
  };
  
  export default connect(null, {
      showNotification,
      push,
  })(UploadAvatarButton);
// in src/users.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, 
         Filter,
         TextInput,
         ReferenceInput,
         Pagination,
         SelectInput} from 'react-admin';

import TemplateGridList from './TemplateGridList'

const styles = {
    numberField: {
        textAlign: 'center'
    },
    deleteButton: {
        withWidth: 30, 
        align: 'right'
    },
    // media: {
    //     height: 0,
    //     paddingTop: '56.25%', // 16:9
    // },
};

const TemplateFilter = ({ permissions, ...props }) =>
    <Filter {...props}>
        <TextInput label="Template Id" source="id" alwaysOn />
        <ReferenceInput label="Category" source="category_id" reference="template-categories">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>;

const TemplateList = ({ classes, ...props }) => (
    <List 
        {...props}
        title="Templates"
        perPage={20} 
        pagination={<Pagination rowsPerPageOptions={[10, 20, 40, 50]} />}
        filters={<TemplateFilter />}
        sort={{ field: "id", order: "ASC" }}>

        <TemplateGridList/>
    </List>
);

export default withStyles(styles)(TemplateList);
import React from 'react';

import { Edit,
        //  NumberInput,
        //  ReferenceManyField,
        //  Datagrid,
        //  EditButton,
        //  TextField,
         TextInput,
         ImageInput,
         ImageField,
         SimpleForm
          } from 'react-admin';

export const EditBackgroundTexture = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list"> 
                <TextInput source="name" />
                <ImageInput source="image" label="Image" accept="image/*">
                    <ImageField source="image_3x.src"/>
                </ImageInput>
        </SimpleForm>
    </Edit>
);
/**
 * Convert a `File` object returned by the upload input into
 * a base 64 string. That's easier to use on FakeRest, used on
 * the ng-admin example. But that's probably not the most optimized
 * way to do in a production database.
 */

function getBase64(rawfile) {
    return new Promise((resolve, reject) => {
        if (rawfile instanceof File) {
            var reader = new FileReader();
            reader.readAsDataURL(rawfile);
            reader.onloadstart = function() {
                console.log("Loading started.");
            };
            reader.onload = function (evt) {
                console.log("Finished loading.");
                resolve(reader.result);
            };
            reader.onerror = function(error) {
                reject(error);
            };
        } else {
            resolve();
        }
    });
}
/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadCapabilities = requestHandler => (type, resource, params) => {

    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'product_type_option_items') {

        var imagePromise = new Promise((resolve, reject) => {
            if (params.data.image.rawFile instanceof File) {
                getBase64(params.data.image.rawFile).then(base64Picture => {
                    params.data.image = base64Picture;
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            } else {
                resolve();
            }
        });

        return new Promise((resolve, reject) => { 
            Promise.all([imagePromise]).then(() => {
                resolve(requestHandler(type, resource, params));
            }).catch(error => {
                reject(error);
            });
        });

    } else if ((type === 'CREATE' || type === 'UPDATE') && resource === 'product_type') {
        console.log("Product Type base64.");

        var imagePromise1 = new Promise((resolve, reject) => {
            if (params.data.image.rawFile instanceof File) {
                getBase64(params.data.image.rawFile).then(base64Picture => {
                    params.data.image = base64Picture;
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            } else {
                resolve();
            }
        });

        return new Promise((resolve, reject) => { 
            Promise.all([imagePromise1]).then(() => {
                resolve(requestHandler(type, resource, params));
            }).catch(error => {
                reject(error);
            });
        });

    } else if ((type === 'CREATE' || type === 'UPDATE') && resource === 'canvas-background-textures') {

        var imagePromise2 = new Promise((resolve, reject) => {
            if (params.data.image.rawFile instanceof File) {
                getBase64(params.data.image.rawFile).then(base64Picture => {
                    params.data.image = base64Picture;
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            } else {
                resolve();
            }
        });

        return new Promise((resolve, reject) => { 
            Promise.all([imagePromise2]).then(() => {
                resolve(requestHandler(type, resource, params));
            }).catch(error => {
                reject(error);
            });
        });

    } else
    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'approvals') {
        console.log("Approval base64 conversion.");

        var imagePromise3 = new Promise((resolve, reject) => {
            if (params.data.image.rawFile instanceof File) {
                getBase64(params.data.image.rawFile).then(base64Picture => {
                    params.data.image = base64Picture;
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            } else {
                resolve();
            }
        });

        return new Promise((resolve, reject) => { 
            Promise.all([imagePromise3]).then(() => {
                resolve(requestHandler(type, resource, params));
            }).catch(error => {
                reject(error);
            });
        });

    } else if ((type === 'CREATE' || type === 'UPDATE') && resource === 'templates') {

        const detailImages = params.data["detail_images"];

        // Step 1.
        // var thumbnail_2x_promise = new Promise((resolve, reject) => {
        //     if (params.data.thumbnail_2x.rawFile instanceof File) {
        //         getBase64(params.data.thumbnail_2x.rawFile).then(base64Picture => {
        //             params.data.thumbnail_2x = base64Picture;
        //             resolve();
        //         }).catch(error => {
        //             reject(error);
        //         });
        //     } else {
        //         resolve();
        //     }
        // });

        // var thumbnail_3x_promise = new Promise((resolve, reject) => {
        //     if (params.data.thumbnail_3x.rawFile instanceof File) {
        //         getBase64(params.data.thumbnail_3x.rawFile).then(base64Picture => {
        //             params.data.thumbnail_3x = base64Picture;
        //             resolve();
        //         }).catch(error => {
        //             reject(error);
        //         });
        //     } else {
        //         resolve();
        //     }
        // });

         var productImagePromise = new Promise((resolve, reject) => {
            if(params.data.product_image) {
                if (params.data.product_image.rawFile instanceof File) {
                    getBase64(params.data.product_image.rawFile).then(base64Picture => {
                        params.data.product_image = base64Picture;
                        resolve();
                    }).catch(error => {
                        reject(error);
                    });
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        });

        var detailedImages_promise = new Promise((resolve, reject) => {
            if (detailImages != null) {
                var base64promises = [];
                var formerPictures = [];
                detailImages.forEach(element => {
                    if (element.rawFile instanceof File) {
                        base64promises.push(getBase64(element.rawFile));
                    } else {
                        formerPictures.push(element);
                    }
                });

                Promise.all(base64promises).then(base64Pictures => {
                    params.data['detail_images'] = [...base64Pictures, ...formerPictures];
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            } else {
                resolve();
            }
        });

        return new Promise((resolve, reject) => { 
            Promise.all([productImagePromise, detailedImages_promise]).then(() => {
                resolve(requestHandler(type, resource, params));
            }).catch(error => {
                reject(error);
            });
        });
    } else if ((type === 'CREATE' || type === 'UPDATE') && resource === 'template-categories') {

        //const detailImages = params.data["detail_images"];

        // Step 1.
        var background_image = new Promise((resolve, reject) => {
            if (params.data.background_image.rawFile instanceof File) {
                getBase64(params.data.background_image.rawFile).then(base64Picture => {
                    params.data.background_image = base64Picture;
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            } else {
                resolve();
            }
        });

        var image = new Promise((resolve, reject) => {
            if (params.data.image.rawFile instanceof File) {
                getBase64(params.data.image.rawFile).then(base64Picture => {
                    params.data.image = base64Picture;
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            } else {
                resolve();
            }
        });

        return new Promise((resolve, reject) => { 
            Promise.all([image, background_image]).then(() => {
                resolve(requestHandler(type, resource, params));
            }).catch(error => {
                reject(error);
            });
        });
    } else {
        return requestHandler(type, resource, params);
    }
};

export default addUploadCapabilities;
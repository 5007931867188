import React from 'react';

import { Edit,
         SimpleForm,
         ReferenceManyField,
         Datagrid,
         EditButton,
         TextField,
         TextInput,
         ImageField } from 'react-admin';

// const AddNewOptionButton = ({ record }) => (
//     <Button
//         component={Link}
//         to={{
//             pathname: '/comments/create',
//             search: `?post_id=${record.id}`
//         }}
//         label="Add a comment"
//     >
//         <ChatBubbleIcon />
//     </Button>
// );

export const EditProductTypeOption = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list"> 
            {/* <TextField source="id" /> */}
            <TextInput source="name" />
            <TextInput source="max_print_side" />

            <ReferenceManyField
                label="Options"
                reference="product_type_option_items"
                target="option_id"
            >

                <Datagrid>
                    <EditButton label="" />
                    <TextField source="value" />
                    <TextField source="display_name" />
                    <ImageField source="image.image_3x.src" label="Image" />
                </Datagrid>
                
            </ReferenceManyField>
            {/* <AddNewCommentButton record={data} /> */}

        </SimpleForm>
    </Edit>
);
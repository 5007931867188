import React from 'react';
import { Show, 
         SimpleShowLayout, 
         TextField, 
         ReferenceField,
         RichTextField } from 'react-admin';

import RTFComponent from '../components/RTFComponent';

export const ShowText = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="frame" />
            <TextField source="font_name" />
            <ReferenceField label="Canvas" source="canvasId" reference="canvases" allowEmpty>
                <TextField source="id" />
            </ReferenceField>
            <RichTextField source="plain" label="Plain Text" />
            
            <RTFComponent source="rtf" label="RTF" />

        </SimpleShowLayout>
    </Show>
);
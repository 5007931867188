import React from 'react';
import { Show, 
    SimpleShowLayout, 
    TextField, 
    ImageField,
    ReferenceManyField,
    EditButton,
    ShowButton,
    Datagrid } from 'react-admin';

export const ShowProductTypeOptionItem = (props) => (
<Show {...props}>
   <SimpleShowLayout>
        <ImageField source="image.image_3x.src"/>
        <TextField source="display_name" />

        <ReferenceManyField
                label="Option Items"
                reference="product_type_option_items"
                target="option_id"
            >
            <Datagrid>
                <TextField source="display_name" />
                <ImageField source="image.image_3x.src" label="Image" />
                <ShowButton label=""/>
                <EditButton />
            </Datagrid>
        </ReferenceManyField>

   </SimpleShowLayout>
</Show>
);
import React from 'react';
import { Show, 
         TextField, 
         ImageField,
         ReferenceManyField,
         Datagrid,
         EditButton,
         //DeleteButton,
         SimpleShowLayout
        } from 'react-admin';
export const ShowCategory = (props) => (
    <Show {...props}>
      <SimpleShowLayout>

            <TextField source="id" />
            <TextField source="name" options={{ fullWidth: true }}/>

            <ImageField source="image" title="title" />
            <ImageField source="background_image" title="title" />

            <ReferenceManyField label="Templates" target="category_id" reference="templates">
            <Datagrid>
                <ImageField source="thumbnail_2x.url" label="iOS Thumbnails - 2x" />
                <TextField source="name" />
                <EditButton label="" />
            </Datagrid>
        </ReferenceManyField>

      </SimpleShowLayout>
    </Show>
);
import React from 'react';

import { Create,
         ReferenceInput,
         TextInput,
         NumberInput,
         ImageField,
         ImageInput,
         SelectInput,
         TabbedForm, 
         FormTab,
         SelectArrayInput,
         ArrayInput,
         BooleanInput,
         ReferenceArrayInput } from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import InlineFormIterator from '../components/InlineFormIterator';

const inputToRecord = v => {
    if (typeof(v) === "string") { 
        return v.replace("$", "")
    } else { 
        return String(v)
    }
}

const recordToInput = v => {
    if (typeof(v) === "string") { 
        return v.replace("$", "")
    }
    return v
}

export const CreateTemplate = ({ permissions, ...props }) =>
    <Create {...props}>
        <TabbedForm redirect="list"> 
            <FormTab label="summary">
                <TextInput source="name" fullWidth={true} />
                
                <BooleanInput label="Published" source="published" defaultValue={false}/>

                <ReferenceInput label="Category" source="category_id" reference="template-categories" allowEmpty fullWidth={true} >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <BooleanInput  label="Featured" source="featured" defaultValue={false}/>

                <NumberInput label="Number of images required" source="image_count" defaultValue={0} step={1} options={{ fullWidth: true }} />

                <ImageInput source="product_image" label="Product Image (Should be equal or bigger than 750x750 pixels)" accept="image/*">
                    <ImageField source="src"/>
                </ImageInput>

                <TextInput multiline label="Text for input box" source="input_information_text" fullWidth={true}  />

                <RichTextInput source="description" />
            </FormTab>

            <FormTab label="Size and Prices">
                <ArrayInput source="prices" label="Pricing" sort={{ field: "id", order: "ASC" }} fullWidth>
                    <InlineFormIterator>

                        <ReferenceInput label="Size" source="size_id" reference="generic-canvas-sizes" fullWidth={true}  sort={{ field: 'surface', order: 'ASC' }}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        <NumberInput label="Price $" source="price" format={recordToInput} parse={inputToRecord}/>

                        <NumberInput label="Gift Wrap Cost $" source="gift_wrap" format={recordToInput} parse={inputToRecord}/>

                    </InlineFormIterator>
                </ArrayInput>
            </FormTab>

            <FormTab label="Steps">
                <ReferenceArrayInput source="steps" reference="steps" label="Required Steps" fullWidth={true} sort={{ field: "id", order: "ASC" }} allowEmpty>
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="Detail Images">
                <ImageInput source="detail_images" label="Images, use a size of 1875x1100 pixels or higher. (Try to keep the aspect ratio)" accept="image/*" placeholder={<p>Drop your pictures here.</p>} multiple>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </FormTab>
            <FormTab label="Properties">
                <RichTextInput source="properties" label="JSON"/>
            </FormTab>
        </TabbedForm>
    </Create>;
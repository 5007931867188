import React from 'react';

import { Create,
        NumberInput,
        TextInput,
        TabbedForm, 
        FormTab,
        ImageInput,
        BooleanInput,
        ImageField} from 'react-admin';

export const CreateProductType = ({ permissions, ...props }) =>
<Create {...props}>
    <TabbedForm redirect="list"> 
        <FormTab label="summary">
            <TextInput source="name" />
            <TextInput source="resource" />
            <TextInput source="identifier" />
            <NumberInput label="Index (used for ordering product types)" defaultValue = {0} source="index" />
            <BooleanInput label="Is Product Type Active (visible in app)" source="active" />
            <ImageInput source="image" label="Image" accept="image/png">
                <ImageField source="image_3x.src" title="title"/>
            </ImageInput>
        </FormTab>
    </TabbedForm>
</Create>
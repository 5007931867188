import NotificationsIcon from '@material-ui/icons/NotificationsActive';

import TemplateNotificationList from './TemplateNotificationList';

import { CreateTemplateNotification } from './CreateTemplateNotification';
import { EditTemplateNotification } from './EditTemplateNotification';

export default {
      list: TemplateNotificationList,
      icon: NotificationsIcon,
      create: CreateTemplateNotification,
      edit: EditTemplateNotification,
}
import Icon from '@material-ui/icons/Texture';
import TexturesList from './TexturesList';
import { EditBackgroundTexture } from './EditBackgroundTexture';
import { CreateBackgroundTexture } from './CreateBackgroundTexture';

export default {
      list: TexturesList,
      icon: Icon,
      edit: EditBackgroundTexture,
      create: CreateBackgroundTexture
}
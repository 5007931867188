import Icon from '@material-ui/icons/FilterFrames';
import List from './ProductTypeOptionList';
import { EditProductTypeOption } from './EditProductTypeOption';
import { ShowProductTypeOption } from './ShowProductTypeOption';

export default {
      list: List,
      icon: Icon,
      edit: EditProductTypeOption,
      show: ShowProductTypeOption,
}
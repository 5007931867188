import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { showNotification as showNotificationAction } from 'react-admin';
import { CREATE } from 'react-admin';
import { push as pushAction } from 'connected-react-router';
import { CircularProgress } from '@material-ui/core';

import dataProvider from '../utils/dataProvider';

class ExportToProductionTemplateButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            exporting: false,
        };

//        console.log(process.env);
    }

    handleClick = () => {
        const { record, showNotification } = this.props;

        this.setState({ exporting: true });

        var url = `templates/export/${record.id}`;
        dataProvider(CREATE, url, { id: record.id }).then((response) => {
            console.log(response);
            showNotification("Template Exported.");
            this.setState({ exporting: false });

            console.log("Finished");
        })
        .catch((e) => {
            this.setState({ exporting: false });
            console.error(e);
            showNotification('Error: Template failed to export template.')
        });
    }

    render() {
        if (this.state.exporting === false) {
            return <Button onClick={this.handleClick} size="small">
                        <CloudUpload/>
                        <span>Push To Production</span>
                   </Button>;
        } else {
            return <CircularProgress size={25} thickness={2} />;
        }
    }
}

ExportToProductionTemplateButton.propTypes = {
    record: PropTypes.object,
    push: PropTypes.func,
    showNotification: PropTypes.func,
    exporting: PropTypes.bool.isRequired,
};

ExportToProductionTemplateButton.defaultProps = {
    exporting: false,
};

export default connect(null, {
    showNotification: showNotificationAction,
    push: pushAction,
})(ExportToProductionTemplateButton);
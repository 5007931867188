// in src/users.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DownloadButton from '../components/DownloadButton'
import { List, 
         Datagrid, 
         ImageField, 
         TextField, 
         DateField,
         ChipField,
         ReferenceField,
         ShowButton } from 'react-admin';

const styles = {
    deleteButton: {
        minWidth: '40px',
        align: 'right'
    }
};

const ImageList = ({ classes, ...props }) => (
    <List title="Images" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <ImageField source="thumbnailUrl" label="Preview" sortable={false}/>
            <TextField source="scope" />
            <ReferenceField label="Order" source="orderId" reference="orders" allowEmpty>
                <TextField source="id" />
            </ReferenceField>     
            <ChipField source="storage" />
            <DateField source="created" showTime />
            <DownloadButton source="edited_image_file" label="Edited Image (On Canvas)" sortable={false}/>
            <DownloadButton source="original_image_file" label="Original Image (Uncropped)" sortable={false}/>
            <ShowButton label=""/>
        </Datagrid>
    </List>
);

export default withStyles(styles)(ImageList);
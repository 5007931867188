import React from 'react';

import { Edit,
         NumberInput,
         ReferenceManyField,
         Datagrid,
         EditButton,
         TextField,
         TextInput,
         TabbedForm, 
         FormTab,
         ImageInput,
         ImageField,
         BooleanInput
          } from 'react-admin';

export const EditProductType = (props) => (
    <Edit {...props}>
        <TabbedForm redirect="list"> 
            <FormTab label="summary">
                <TextInput source="name" />
                <TextInput source="resource" />
                <TextInput source="identifier" />
                <NumberInput label="Index (used for ordering items)" defaultValue = {0} source="index" />

                <ImageInput source="image" label="Image" accept="image/png">
                    <ImageField source="image_3x.src" title="title"/>
                </ImageInput>

                <BooleanInput label="Is Product Type Active (visible in app)" source="active" defaultValue={false} />

            </FormTab>
            <FormTab label="Product Type Options">
                <ReferenceManyField reference="product_type_option" target="product_type" label="">
                    <Datagrid>
                        <EditButton label="" />
                        {/* <TextField source="id" /> */}
                        <TextField source="name" />
                        {/* <EditButton /> */}
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
);
import React from 'react';

import { Create,
         ReferenceInput,
         NumberInput,
         SimpleForm,
         AutocompleteInput } from 'react-admin';

const inputToRecord = v => {
    if (typeof(v) === "string") { 
        return v.replace("$", "")
    } else { 
        return String(v)
    }
}

const recordToInput = v => {
    if (typeof(v) === "string") { 
        return v.replace("$", "")
    }
    return v
}
export const CreateTemplateSizePrice = ({ permissions, ...props }) =>
    <Create title="Create Template Size and Price" {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput label="Template"
                source="template_id"
                reference="templates"
                filterToQuery={searchText => ({ name: searchText })}
                allowEmpty>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput label="Size"
                source="size_id"
                reference="canvas-sizes"
                filterToQuery={searchText => ({ name: searchText })}
                allowEmpty>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <NumberInput source="price" format={recordToInput} parse={inputToRecord}/>
            <NumberInput source="gift_wrap" format={recordToInput} parse={inputToRecord}/>
        </SimpleForm>
    </Create>;
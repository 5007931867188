import React from 'react';
import { Show, 
    SimpleShowLayout, 
    TextField, 
    ReferenceField, 
    RichTextField, 
    ImageField,
    DeleteButton,
    NumberField,
    ReferenceManyField,
    EditButton,
    Datagrid } from 'react-admin';

export const ShowTemplate = (props) => (
<Show {...props}>
   <SimpleShowLayout>

        <TextField source="name" />
        <ReferenceField label="Category" source="category_id" reference="template-categories" link={false}>
            <TextField source="name" />
        </ReferenceField>
        <TextField source="image_count" />

        <RichTextField source="input_information_text" />
        <RichTextField source="description" />
    
        <ImageField source="thumbnail_2x" label="iOS Thumbnails - 2x" />
        <ImageField source="thumbnail_3x" label="iOS Thumbnails - 3x" />

        <ReferenceManyField label="Steps" target="template_id" reference="template-steps">
            <Datagrid>
                <ReferenceField label="Name" source="step_id" reference="steps" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <DeleteButton label=""/>
            </Datagrid>
        </ReferenceManyField>

        <ReferenceManyField label="Size & Prices" target="template_id" reference="template-prices">
            <Datagrid>
                <ReferenceField label="Size" source="canvas_size" reference="canvas-sizes" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Finish" source="material" reference="materials" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="price" options={{ style: 'currency' }}/>      

                <EditButton label="" />
                <DeleteButton label="" />
            </Datagrid>
        </ReferenceManyField>

        <ReferenceManyField label="Steps" target="template_id" reference="template-steps">
            <Datagrid>
                <ReferenceField label="Size" source="step_id" reference="steps" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <DeleteButton label="" />
            </Datagrid>
        </ReferenceManyField>

        <ReferenceManyField label="Detail Images" target="template_id" reference="template-detail-images">
            <Datagrid>
                <ImageField source="thumbnail_url" />
                <DeleteButton label=""/>
            </Datagrid>
        </ReferenceManyField>

   </SimpleShowLayout>
</Show>
);
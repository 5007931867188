import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import DownloadIcon from '@material-ui/icons/CloudDownload';
import { connect } from 'react-redux';

import dataProvider from '../utils/dataProvider';
import { GET_ONE, GET_LIST, LinearProgress } from 'react-admin';
//import IconButton from '@material-ui/core/IconButton';

// const styles = {
//     inline: {
//         display: 'block',
//         margin: 'auto',
//     },
// };

class ShowProductOptionItemField extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };

        const { record } = this.props;

        this.state = { loading: true };

        const payload = {
            filter: { canvas_product_option_id: record.id },
            pagination: { page: 1, perPage: 100 }
        };

        dataProvider(GET_LIST, `canvas_product_option_item`, payload).then((response) => {
            let row = response.data[0];
            if (row != null) {
                return dataProvider(GET_ONE, `product_type_option_items`, { id: row.option_item_id });
            } else {
                this.setState({ loading: false });
            }
        })
        .then((response) => {
            var option_item = response.data;
            this.setState({ loading: false, name: option_item.display_name });
        })
        .catch((e) => {
            this.setState({ loading: false });
            console.error(e);
        });
    }
     
    render() {
        const { loading, name } = this.state;
        //const { record } = this.props;

        if (loading === true) {
            return <LinearProgress />;
        } else {
            return <div>{name}</div>;
        }
    }
}

ShowProductOptionItemField.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {})(ShowProductOptionItemField);
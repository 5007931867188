import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import get from 'lodash.get';

class RTFComponent extends React.Component {
  
    constructor(props) {
      super(props)
      this.state = { text: '' } // You can also pass a Quill Delta here
      this.handleChange = this.handleChange.bind(this)
    }
  
    handleChange(value) {
      this.setState({ text: value })
    }

    render() {
      return (
        <ReactQuill value={get(this.props.record, this.props.source)} onChange={this.handleChange} />
      )
    }
  }

RTFComponent.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default RTFComponent;
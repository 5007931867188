import React, { Component } from 'react';
import { Responsive } from 'react-admin';
import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import NewInstalls from './NewInstalls';
import ItemsInCart from './ItemsInCart';
import Welcome from './Welcome';
import { push } from 'connected-react-router';
import apiUrl from '../config';

//import genericDataProvider from '../utils/dataProvider';
//import addUploadFeature from '../utils/addUploadCapabilities';

//const dataProvider = addUploadFeature(genericDataProvider);

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'row' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};


class Dashboard extends Component {
    state = {};

    componentDidMount() {
        // const aMonthAgo = new Date();
        // aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    
        // //dataProvider => {
        // dataProvider(GET_MANY, 'orders', {
        //     filter: { created: aMonthAgo.toISOString() },
        //     sort: { field: 'date', order: 'DESC' },
        //     pagination: { page: 1, perPage: 50 },
        // })
        // .then(response => {
        //     return response.data.reduce( (stats, order) => {
        //             //if (order.status !== '6') {
        //             var totalStr = order.total.replace('$', '').replace(',', '');   

        //             stats.revenue += Number(totalStr);
        //             stats.nbNewOrders++;
        //             return stats;
        //         },
        //         {
        //             revenue: 0,
        //             nbNewOrders: 0,
        //         }
        //     )
        // })  
        // .then(({ revenue, nbNewOrders }) => {

        //     this.setState({
        //         revenue: revenue.toLocaleString(undefined, {
        //             style: 'currency',
        //             currency: 'USD',
        //             minimumFractionDigits: 0,
        //             maximumFractionDigits: 0,
        //         }),
        //         nbNewOrders,
        //     });
        // });

        var url = `${apiUrl}/dashboard`;
        const options = { 
            headers : new Headers({
                Accept: 'application/json'
            }),
        };
    
        const token = localStorage.getItem('token');

        // Authorization token.
        if(token) { 
          options.headers.set('Authorization', token); 
        }

        fetch(url, options).then(response => {
            if (response.status === 401 || response.status === 403) {

                localStorage.removeItem('username');
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                localStorage.removeItem('avatar');
    
                push('/login');

                return Promise.reject('Failed authorization.');
            } else {
                return response.json();
            }
        }).then(json => {
            var data = json.data;

            var installs = data.installs;
            var nbNewOrders = data.new_orders;
            var revenue = data.revenue;
            var itemsInCart = data.items_in_cart;

            this.setState({
                revenue: revenue.toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }),
                nbNewOrders,
                installs,
                itemsInCart
            });

        });
    }

    render() {
        const {
            nbNewOrders,
            revenue,
            installs,
            itemsInCart,
        } = this.state;
        return (
            <Responsive
                xsmall={
                    <div>
                        <div style={styles.flexColumn}>
                            <div style={{ marginBottom: '2em' }}>
                                <br />
                            </div>
                            <div style={styles.flex}>
                                <MonthlyRevenue value={revenue} />
                                <NbNewOrders value={nbNewOrders} />
                                <NewInstalls value={installs} />
                                <ItemsInCart value={itemsInCart} />
                            </div>
                        </div>
                    </div>
                }
                small={
                    <div style={styles.flexColumn}>
                        <div style={styles.singleCol}>
                            <br />
                        </div> 
                        <div style={styles.flex}>
                            <MonthlyRevenue value={revenue} />
                            <NbNewOrders value={nbNewOrders} />
                            <NewInstalls value={installs} />
                            <ItemsInCart value={itemsInCart} />
                        </div>
                    </div>
                }
                medium={
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <div style={styles.singleCol}>
                            </div> 
                            <div style={styles.flex}>
                                <MonthlyRevenue value={revenue} />
                                <NbNewOrders value={nbNewOrders} />
                                <NewInstalls value={installs} />
                                <ItemsInCart value={itemsInCart} />
                            </div>
                            {/* <div style={styles.singleCol}>
                                <Welcome />
                            </div> */}
                        </div>
                        {/* <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                <NewCustomers
                                    nb={nbNewCustomers}
                                    visitors={newCustomers}
                                />
                            </div>
                        </div> */}
                    </div>
                }
            />
        );
    }
}

export default Dashboard;

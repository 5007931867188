// in src/users.js
import React from 'react';
import { List } from 'react-admin';

import TextureGridList from './TextureGridList'

// const ProductTypeShow = props => (
//     <Show
//         {...props}
//         /* disable the app title change when shown */
//         title=" "
//     >
//         <SimpleShowLayout>
//             <TextField source="id" />
//             <TextField source="name" />
//             <TextField source="value" />
//             <NumberField label="Index (used for ordering items)" defaultValue = {0} source="index" />

//             <ReferenceManyField
//                 label="Product Options"
//                 reference="product_type_option"
//                 target="product_type"
//             >
//                 <Datagrid>
//                     <ShowButton label=""/>

//                     {/* <TextField source="id" /> */}
//                     <TextField source="name" />

//                     {/* <EditButton /> */}
//                 </Datagrid>
//             </ReferenceManyField>

//         </SimpleShowLayout>
//     </Show>
// );

const TexturesList = ({ classes, ...props }) => (
    // <List title="Product Type List" {...props} perPage={25}>
    //     <Datagrid expand={<ProductTypeShow />}>
    //         {/* <ShowButton label=""/> */}
    //         <ImageField source="image_2x" label="Image" />
    //         <TextField source="name" />
    //         <EditButton label="" />

    //         <ProductTypeList/>
    //     </Datagrid>
    // </List>

    <List title="Product Type List" {...props} perPage={25}>
        <TextureGridList/>
    </List>
);

export default (TexturesList);
import Icon from '@material-ui/icons/Label';
import CategoryList from './CategoryList';
import { ShowCategory } from './ShowCategory';
import { CreateCategory } from './CreateCategory';
import { EditCategory } from './EditCategory';

export default {
      list: CategoryList,
      show: ShowCategory,
      create: CreateCategory,
      edit: EditCategory,
      icon: Icon,
}
import React from 'react';

import { Edit,
         ReferenceInput,
         NumberInput,
         SelectInput,
         SimpleForm,
         TextInput,
         required
        } from 'react-admin';

import withStyles from '@material-ui/core/styles/withStyles';

import { styles } from './CreateCustomCanvasSizePrice';

const EditCustomCanvasSizePrice = ({classes, ...props}) =>
    <Edit title="Edit Price" {...props}>
        <SimpleForm redirect="list">
            <TextInput source="id" disabled/>

            <ReferenceInput label="Size" source="size_id" reference="generic-canvas-sizes" filter={{ opaque: true}}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <input type="hidden" placeholder="" name="size_id" value="0" />

            <NumberInput source="price" validate={required()} className={classes.price} formClassName={classes.priceFormGroup} defaultValue={0} min={0} />
            <NumberInput source="gift_wrap" validate={required()} className={classes.price} formClassName={classes.giftFormGroup} defaultValue={0} min={0} />
        </SimpleForm>
    </Edit>;

export default withStyles(styles)(EditCustomCanvasSizePrice);
import React from 'react';
import { List, 
         Datagrid, 
         TextField, 
         DateField,
         DeleteButton,
         BooleanField,
         ShowButton } from 'react-admin';
import NullableNumberField from '../components/NullableNumberField'

const CouponsList = ({ classes, ...props }) => (
    <List title="Coupons" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <NullableNumberField source="discount" label="Discount(%)"/ >
            <NullableNumberField source="amount" label="Amount($)" /> 
            <DateField source="expiration_date" />
            <TextField source="code" label="Code"/>
            <TextField source="use_count" label="Use count"/>
            <BooleanField source="single_use" />
            <DeleteButton sortable={false}/>
            <ShowButton label=""/>
        </Datagrid>
    </List>
);

export default (CouponsList);
import React from 'react';
import { Show, 
    SimpleShowLayout, 
    TextField, 
    ImageField,
    ReferenceManyField,
    EditButton,
    Datagrid } from 'react-admin';

export const ShowProductTypeOption = (props) => (
<Show {...props}>
   <SimpleShowLayout>
        <ImageField source="thumbnail_2x" label="iOS Thumbnails - 2x" />
        <TextField source="name" />
        
        {/* <ImageField source="thumbnail_3x" label="iOS Thumbnails - 3x" /> */}

        <ReferenceManyField
                label="Option Items"
                reference="product_type_option_items"
                target="option_id"
            >
            <Datagrid>
                <ImageField source="image.image_3x.src" label="Image" />
                <TextField source="display_name" />
                <EditButton label = "" />
            </Datagrid>
        </ReferenceManyField>

   </SimpleShowLayout>
</Show>
);
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TemplatePriceList from './TemplatePriceList';

import { CreateTemplateSizePrice } from './CreateTemplateSizePrice';
import { EditTemplateSizePrice } from './EditTemplateSizePrice';

export default {
      list: TemplatePriceList,
      create: CreateTemplateSizePrice,
      edit: EditTemplateSizePrice,
      icon: AttachMoneyIcon,
}
import React from 'react';
import { Show, SimpleShowLayout, TextField, ImageField } from 'react-admin';

export const ShowImage = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="scope" />
            <ImageField source="fileUrl" label="Preview" sortable={false}/>
        </SimpleShowLayout>
    </Show>
);
import React from 'react';

import { Edit,
         ReferenceInput,
         SelectInput,
         TextInput,
         SimpleForm } from 'react-admin';


export const EditTemplateNotification = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list">            
            <ReferenceInput label="Order Status" source="action" reference="notification-actions">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="email" label="Email content" options={{ fullWidth: true }} multiline />
            <TextInput source="push_notification" label="Push Notification content" options={{ fullWidth: true }} multiline />
        </SimpleForm>
    </Edit>
);
// in src/users.js
import React from 'react';
import { List, 
         Datagrid, 
         TextField } from 'react-admin';

const StepList = ({ classes, ...props }) => (
    <List title="Steps (or actions) available for templates" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="action_button_name" />
            <TextField source="properties" />
        </Datagrid>
    </List>
);

export default (StepList);
import React from 'react';
import Card from '@material-ui/core/Card';
import { CardContent, CardActions, CardHeader } from '@material-ui/core';

import { Title } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import Avatar from 'react-avatar-edit'

import UploadAvatarButton from './UploadAvatarButton';

const styles = {
    button: { margin: '1em' },
    card: { 
        //display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '350px',
    },
    preview_div: {
        width: '100%',
        'text-align': 'center',
    }
};

class Configuration extends React.Component {
    
    constructor(props) {
        super(props)

        var src;
        this.state = {
            preview: null,
            src
        }

        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    }
    
    onClose() {
        this.setState({preview: null})
    }
    
    onCrop(preview) {
        this.setState({preview})
    }
  
    onBeforeFileLoad(elem) {
        if(elem.target.files[0].size > 8500680){
            alert("File is too big!");
            elem.target.value = "";
        };
    }
    
    render () {
      const { classes } = this.props;

      return (
        <div className={classes.details}>
            <Card className={classes.card}>
                <CardHeader title="User Profile Avatar" subheader="Upload your avatar image in order for clients to see you in the support chat." />
                <Title title="Configurations" />                
                    <CardContent className={classes.cardcontent}>
                        <Avatar
                            width={290}
                            height={195}
                            onCrop={this.onCrop}
                            onClose={this.onClose}
                            onBeforeFileLoad={this.onBeforeFileLoad}
                            src={this.state.src}
                        />
                        <br/>
                        <div className={classes.preview_div}>
                            <img src={this.state.preview} alt="" name="preview" />
                        </div>
                    </CardContent>
                    <CardActions>
                        <UploadAvatarButton preview={this.state.preview}/>
                    </CardActions>
               
            </Card>
        </div>
      )
    }
}

// const Configuration = ({
//     classes,
//     preview,
//     src,
//     onCrop,
//     onClose,
// }) => (
//     <Card>
//         <Title title="Configurations" />
//         <CardContent>

//             <Avatar
//                 width={190}
//                 height={195}
//                 onCrop={onCrop}
//                 onClose={onClose}
//                 src={src}
//             />

//             <img src={preview} alt="Preview" />
//         </CardContent>
//     </Card>
// );

// const mapStateToProps = state => ({
//     // theme: state.theme,
//     // locale: state.i18n.locale,
// });

// const enhance = compose(
//     connect(
//         mapStateToProps,
//         {
//             // changeLocale,
//             // changeTheme,
//         }
//     ),
//     translate,
//     withStyles(styles)
// );

export default withStyles(styles)(Configuration);

import React from 'react';
import { Show, 
         TextField, 
         ChipField, 
         DateField, 
         ReferenceField, 
         RichTextField, 
         ReferenceManyField,
         Datagrid,
         EmailField,
         ImageField,
         DeleteButton,
         ShowButton
        } from 'react-admin';
//import PrintShippingLabelButton from '../components/PrintLabelButton';
import { TabbedShowLayout, Tab } from 'react-admin';

const MyChip = ({record,source, ...props}) => (
    record && (record[source]!==undefined)?<ChipField {...{record, source, ...props}} /> : <span>✘</span>
);

const CanvasImageHeader = ({ record, basePath }) => {
    return (record.template_id !== null) ? 
                                            ( <ReferenceField basePath={basePath} label="Template" source="template_id" reference="templates" link="show" record={record} allowEmpty>
                                                <ImageField source="thumbnail_2x.url"/>
                                              </ReferenceField> )
                                          : ( 
                                                <ImageField basePath={basePath} source="screenshot_file" record={record} label="Screenshot"/> 
                                            );
};

export const ShowOrder = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <ImageField source="thumbnail_url" label="Canvas Preview" sortable={false}/>
{/* 
                <ReferenceField label="Payment" source="paymentId" reference="payments" link="show" allowEmpty>
                    
                </ReferenceField>  */}

                <MyChip source="total" />

                <DateField source="created" showTime />
                <RichTextField source="notes" />
                <ReferenceField label="Shipping Method" source="shipping_method_id" reference="shipping"  link={false} allowEmpty>
                    <ChipField source="label" />
                </ReferenceField> 
                <ReferenceField label="Used Coupone" source="coupone_id" reference="coupons"  link={false} allowEmpty>
                    <MyChip source="name" />
                </ReferenceField> 
                {/* <PrintShippingLabelButton label="Print Label" sortable={false} printing={false} elStyle={{ display: 'block' }} /> */}
            </Tab>

            <Tab label="Shipping Address">
                <ReferenceManyField label="Shipping Address" target="orderId" reference="address">
                    <Datagrid>
                        <EmailField source="email" />
                        <TextField source="firstName" />       
                        <TextField source="lastName" />
                        <TextField source="phone" />
                        <TextField source="street" />
                        <TextField source="city" />
                        <TextField source="state" />
                        <TextField source="country" />
                        <TextField source="zip" />
                        <DateField source="created" showTime />             
                    </Datagrid>
                </ReferenceManyField>
            </Tab>

            <Tab label="Prints">
                <ReferenceManyField label="Canvases" reference="canvases" target="orderId">
                    <Datagrid>
                        <ShowButton label="" elStyle={{ minWidth: '40px', display: 'block' }} />
                        <TextField source="id" label="Canvas Id"/> 
                        <CanvasImageHeader/>
                        <ReferenceField label="Size" source="size" reference="canvas-sizes" link="show">
                            <ChipField source="name" />
                        </ReferenceField> 
                        <TextField source="orientation" label="Orientation"/>   
                        <TextField source="quantity" label="Quantity" />           
                        <DeleteButton sortable={false} elStyle={{ minWidth: '40px', display: 'block' }} />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
import React from 'react';

import { Edit,
         NumberInput,
         TextInput,
         TabbedForm, 
         FormTab,
         ImageInput,
         ImageField,
         ArrayInput,
         SelectInput,
         ReferenceInput } from 'react-admin';

import InlineFormIterator from '../components/InlineFormIterator';

const PostTitle = ({ record }) => {
    return <span>Option {record ? `"${record.display_name}"` : ''}</span>;
};

const redirect = (basePath, id, data) => `/product_type_option/${data.option_id}/show`;

export const EditProductTypeOptionItem = (props) => (
    <Edit title={<PostTitle />} {...props}>
        <TabbedForm redirect={redirect}>

            <FormTab label="summary">
                <TextInput source="display_name" />

                <ImageInput source="image" label="Image" accept="image/png">
                    <ImageField source="image_3x.src"/>
                </ImageInput>

                {/* <TextInput source="max_print_side" /> */}
            </FormTab>

            <FormTab label="Prices">
                <ArrayInput source="prices" label="Pricing" sort={{ field: "id", order: "ASC" }} fullWidth>
                    <InlineFormIterator>
                        <ReferenceInput label="Size" source="size_id" reference="generic-canvas-sizes" sort={{ field: 'surface', order: 'ASC' }}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <NumberInput label="Price $" defaultValue = {0} source="price" />
                    </InlineFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);
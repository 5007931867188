import React from 'react';
import { Show, 
        TextField, 
        DateField, 
        ReferenceField, 
        RichTextField, 
        ImageField,
        DeleteButton,
        NumberField,
        ReferenceManyField,
        ShowButton,
        //Query,
        //Loading,
        SingleFieldList,
        //ChipField,
        Datagrid } from 'react-admin';

import { TabbedShowLayout, Tab } from 'react-admin'
import DownloadButton from '../components/DownloadButton'
import GiftField from '../components/GiftField'
import ShowProductOptionItemField from '../components/ShowProductOptionItemField';
import CanvasBackgroundStyle from '../components/CanvasBackgroundStyle';

const CanvasImageHeader = ({ record, basePath }) => {
    return (record.template_id !== null) ? 
                                            ( <ReferenceField basePath={basePath} label="Template" source="template_id" reference="templates" link="show" record={record} allowEmpty>
                                                <ImageField source="thumbnail_2x.url"/>
                                              </ReferenceField> )
                                          : ( 
                                                <ImageField basePath={basePath} source="screenshot_file" record={record} /> 
                                            );
};

export const CanvasShow = (props) => (
<Show {...props}>
   <TabbedShowLayout>
        <Tab label="summary">
            <CanvasImageHeader />

            <GiftField source="gift_wrap" label="Gift Wrapped" />

            <DateField source="created" showTime />
            <ReferenceField label="Order" source="orderId" reference="orders">
                <TextField source="id" />
            </ReferenceField>
            
            <ReferenceManyField label="Background Style" target="canvas_id" reference="canvas-background" link={false}>
                <SingleFieldList>
                    <CanvasBackgroundStyle/>
                </SingleFieldList>
            </ReferenceManyField>

            <ReferenceField label="Size" source="size" reference="canvas-sizes" link={false}>
                <TextField source="name" />
            </ReferenceField>

            <NumberField source="quantity" style={{ textAlign: 'left' }}/> 
            <NumberField source="image_count" style={{ textAlign: 'left' }}/>

            <ReferenceManyField label="Product Options" target="canvas_id" reference="canvas_product_options" link={false}>
                <Datagrid>
                    <ReferenceField label="Name" source="product_option_id" reference="product_type_option" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ShowProductOptionItemField label="Selected Option Item/Value" />
                </Datagrid>
            </ReferenceManyField>
            
            <ReferenceManyField label="Canvas Meta Data" target="canvas_id" reference="canvas-meta" link={false}>
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" /> 
                    <TextField source="value" /> 
                    <TextField source="type" /> 
                </Datagrid>
            </ReferenceManyField>

        </Tab>

        <Tab label="images">
        <ReferenceManyField label="Images" reference="images" target="canvasId">
            <Datagrid>
                <ImageField source="thumbnailUrl" label="Screenshot"/>
                <TextField source="id" label="Id"/>
                <TextField source="scope" label="Scope" />           
                <DownloadButton source="edited_image_file" label="Edited Image (On Canvas)" sortable={false}/>
                <DownloadButton source="original_image_file" label="Original Image (Uncropped)" sortable={false}/>
                <DeleteButton sortable={false} elStyle={{ minWidth: '40px', display: 'block' }} />
            </Datagrid>
        </ReferenceManyField>
        </Tab>

        <Tab label="texts">
            <ReferenceManyField label="Texts" reference="texts" target="canvasId">
                <Datagrid>
                    <ShowButton label="" elStyle={{ minWidth: '40px', display: 'block' }} />
                    <TextField source="id" />
                    <TextField source="frame" />
                    <RichTextField source="plain" label="Plain Text" />
                    <DeleteButton label="" style={{ minWidth: '40px', align: 'right'}}/>
                </Datagrid>
            </ReferenceManyField>
        </Tab>
   </TabbedShowLayout>
</Show>
);
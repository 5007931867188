import React from 'react';

import { Create,
         TextInput,
         SimpleForm } from 'react-admin';

export const CreateUser = (props) => (
    <Create title="Create an Admin User" {...props}>
        <SimpleForm redirect="list">            
            <TextInput label="Email Address" source="email" type="email" />
            <TextInput label="Password" source="password" type="password" />
            <TextInput label="Re-type password" source="r-password" type="password" />
            <TextInput source="notes" multiline />
        </SimpleForm>
    </Create>
);
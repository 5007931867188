// in src/users.js
import React from 'react';
import { List, 
         Datagrid, 
         EmailField, 
         TextField, 
         DateField,
         DeleteButton,
         //NumberField,
         ReferenceField,
         ShowButton } from 'react-admin';
import { CurrencyNumberField } from '../components/CurrencyNumberField'


const PaymentList = ({ classes, ...props }) => (
    <List title="Payments" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <CurrencyNumberField source="amount" options={{ style: 'currency' }}/>
            <EmailField source="email" />
            <DateField source="date" showTime/>
            <ReferenceField label="User" source="userId" reference="users" link="show" allowEmpty>
                <TextField source="email" />
            </ReferenceField>
            <DeleteButton sortable={false}/>
            <ShowButton label=""/>
        </Datagrid>
    </List>
);

export default (PaymentList);
import React from 'react';

import { Edit,
         SimpleForm,
         NumberInput,
         TextInput,
         AutocompleteInput,
         ReferenceInput } from 'react-admin';

const inputToRecord = v => {
    if (typeof(v) === "string") { 
        return v.replace("$", "")
    } else { 
        return String(v)
    }
}

const recordToInput = v => {
    if (typeof(v) === "string") { 
        return v.replace("$", "")
    }
    return v
}

export const EditTemplateSizePrice = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list"> 
            <TextInput source="id" disabled/>

            <ReferenceInput label="Template" source="template_id" reference="templates" link="show" allowEmpty>
                <TextInput source="name" disabled/>
            </ReferenceInput>

            <ReferenceInput label="Canvas Sizes" source="size_id" reference="canvas-sizes" link="show" allowEmpty>
                <TextInput optionText="name" disabled/>
            </ReferenceInput>

            <ReferenceInput label="Finish" source="material" reference="materials" link="show" allowEmpty>
                <AutocompleteInput source="name" />
            </ReferenceInput>

            <NumberInput source="price" format={recordToInput} parse={inputToRecord}/>
            <NumberInput source="gift_wrap" format={recordToInput} parse={inputToRecord}/>
        </SimpleForm>
    </Edit>
);
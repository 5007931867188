import React from 'react';

import { Edit,
         ReferenceInput,
         SelectInput,
         TabbedForm,
         FormTab,
         TextInput,
         ReferenceArrayInput,
         SelectArrayInput,
         NumberInput,
         ImageInput,
         ArrayInput,
         CardActions,
         ShowButton,
         ListButton,
         DeleteButton,
         RefreshButton,
         BooleanInput,
         ImageField } from 'react-admin';

import DuplicateTemplateButton from '../components/DuplicateTemplateButton';
import ExportToProductionTemplateButton from '../components/ExportToProductionTemplateButton';
import InlineFormIterator from '../components/InlineFormIterator';
import RichTextInput from 'ra-input-rich-text';

const inputToRecord = v => {
    if (typeof(v) === "string") { 
        return v.replace("$", "")
    } else { 
        return String(v)
    }
}

const recordToInput = v => {
    if (typeof(v) === "string") { 
        return v.replace("$", "")
    }
    return v
}

const TemplateEditActions = ({ basePath, data, resource }) => (
    <CardActions>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
        <DuplicateTemplateButton record={data} />
        
        {
            (process.env.REACT_APP_ENV === "development") ? <ExportToProductionTemplateButton record={data} /> : <p/>
        }
        
        <DeleteButton basePath={basePath} record={data} resource={resource} />
        <RefreshButton />
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
);

// const styles = {
//     deleteButton: {
//         minWidth: '40px',
//         align: 'right'
//     }
// };

// const priceInputStyles = {
//     inline: {
//         display: 'block',
//         margin: 'auto',
//     },
// };

export const EditTemplate = (props) => (
    <Edit actions={<TemplateEditActions />} {...props}>
        <TabbedForm redirect="list"> 
            <FormTab label="summary">
                <TextInput source="name" fullWidth={true}  />
                
                <BooleanInput label="Published" source="published" defaultValue={false}/>

                <ReferenceInput label="Category" source="category_id" reference="template-categories" fullWidth={true}  allowEmpty>
                    <SelectInput optionText="name" fullWidth={true}  />
                </ReferenceInput>

                <BooleanInput  label="Featured" source="featured" defaultValue={false}/>

                <NumberInput label="Number of images required" source="image_count" defaultValue={0} step={1} options={{ fullWidth: true }} />

                <ImageInput source="product_image" label="Product Image (Should be equal or bigger than 750x750 pixels)" accept="image/*">
                    <ImageField source="src"/>
                </ImageInput>

                <TextInput multiline label="Text for input box" source="input_information_text" fullWidth={true} />

                <RichTextInput source="description" />
            </FormTab>
 
            <FormTab label="Prices" >
                 <ArrayInput source="prices" label="Pricing" sort={{ field: "id", order: "ASC" }} fullWidth>
                     <InlineFormIterator>
  
                        <ReferenceInput label="Size" source="size_id" reference="generic-canvas-sizes" sort={{ field: 'surface', order: 'ASC' }}>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>

                        <NumberInput label="Price $" source="price" format={recordToInput} parse={inputToRecord}/>

                        <NumberInput label="Gift Wrap Cost $" source="gift_wrap" format={recordToInput} parse={inputToRecord}/>

                    </InlineFormIterator>
                </ArrayInput> 
            </FormTab>

            <FormTab label="Steps">
                <ReferenceArrayInput source="steps" reference="steps" fullWidth={true}  label="Required Steps" sort={{ field: "id", order: "ASC" }} allowEmpty>
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
            </FormTab>

            <FormTab label="Detail Images">
                <ImageInput source="detail_images" label="Images, use a size of 1875x1100 pixels or higher. (Try to keep the aspect ratio)" accept="image/*" placeholder={<p>Drop your pictures here.</p>} multiple>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </FormTab>
            
            <FormTab label="Properties">
                <RichTextInput source="properties" />
            </FormTab>
        </TabbedForm>
    </Edit>
);
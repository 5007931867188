import React from 'react';

import { Edit,
         required,
         TextInput,
         SimpleForm } from 'react-admin';

export const EditOrderApproval = (props) => (
    <Edit title="Edit an Order Approval Request" {...props}>
        <SimpleForm redirect="list">            
            <TextInput source="order_id" disabled />
            <TextInput source="email" disabled />
            <TextInput source="notes" label="Notes to customer." validate={required()}/>
        </SimpleForm>
    </Edit>
);
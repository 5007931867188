import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ContentCopy from '@material-ui/icons/FileCopy';
import { showNotification as showNotificationAction } from 'react-admin';
import { CREATE } from 'react-admin';
import { push as pushAction } from 'connected-react-router';

import dataProvider from '../utils/dataProvider';
//import { templateDuplicate as templateDuplicateAction } from '../actions/templateDuplicateAction';

class DuplicateTemplateButton extends Component {
    handleClick = () => {
        const { push, record, showNotification } = this.props;

        var url = `templates/duplicate/${record.id}`;
        dataProvider(CREATE, url, { id: record.id }).then((response) => {
            console.log(response);
            showNotification("Template duplicated. You are now viewing the duplicated template.");
            push('/templates/' + response.data.id);
        })
        .catch((e) => {
            console.error(e);
            showNotification('Error: Template failed to duplicate.')
        });
    }

    render() {
        return <Button onClick={this.handleClick} size="small">
                    <ContentCopy/> Duplicate
               </Button>;
    }
}

DuplicateTemplateButton.propTypes = {
    record: PropTypes.object,
    push: PropTypes.func,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
    push: pushAction,
})(DuplicateTemplateButton);
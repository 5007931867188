import React from 'react';

import { Create,
        ReferenceInput,
        NumberInput,
        SelectInput,
        SimpleForm } from 'react-admin';

import withStyles from '@material-ui/core/styles/withStyles';

export const styles = {
    price: { width: '5em' },
    priceFormGroup: { display: 'inline-block' },
    giftFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const CreateCustomCanvasSizePrice = ({classes, ...props}) => (
    <Create title="Create Custom Canvas Price" {...props}>
        <SimpleForm redirect="list">            

            <ReferenceInput label="Size" source="size_id" reference="generic-canvas-sizes" >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <NumberInput source="price" className={classes.price} formClassName={classes.priceFormGroup} defaultValue={0} min={0} />
            <NumberInput source="gift_wrap" className={classes.price} formClassName={classes.giftFormGroup} defaultValue={0} min={0} />
            
        </SimpleForm>
    </Create>
);

export default withStyles(styles)(CreateCustomCanvasSizePrice);
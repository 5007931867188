// in src/users.js
import React from 'react';
import { List, 
         Datagrid, 
         TextField, 
         ReferenceField,
         EditButton,
         DeleteButton,
         ChipField
         } from 'react-admin';

const TemplateNotificationList = ({ classes, ...props }) => (
    <List title="Notification Templates" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField label="For Action" source="action" reference="notification-actions" link="false" allowEmpty>
                <ChipField source="name" />
            </ReferenceField>
            <TextField source="email" label="Email content" />
            <TextField source="push_notification" label="iOS Push Notification content" />
            <EditButton label=""/>
            <DeleteButton label=""/>
        </Datagrid>
    </List>
);

export default (TemplateNotificationList);
import React from 'react';

import { Create,
         TextInput,
         ImageField,
         ImageInput,
         SimpleForm } from 'react-admin';

export const CreateCategory = ({ permissions, ...props }) => (
      <Create {...props}>
            <SimpleForm redirect="list"> 
                  <TextInput source="name" options={{ fullWidth: true }}/>

                  <ImageInput source="image" label="Main thumbnail category image size should be 372x456">
                        <ImageField source="src" title="title" />
                  </ImageInput>

                  <ImageInput source="background_image" label="Main Category Header Background image. Size should be 1200x1200">
                        <ImageField source="src" title="title" />
                  </ImageInput>
            </SimpleForm>
      </Create>
);
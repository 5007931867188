import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, 
         EditButton,
         DeleteButton,
         Datagrid,
         TextField,
         ShowButton,
         ImageField
      } from 'react-admin';

const styles = {
      numberField: {
          textAlign: 'center'
      },
      deleteButton: {
          withWidth: 30, 
          align: 'right'
      },
      // media: {
      //     height: 0,
      //     paddingTop: '56.25%', // 16:9
      // },
  };

const CategoryList = ({ classes, ...props }) => (
    <List title="Templates" {...props} perPage={25} sort={{ field: "id", order: "ASC" }}>
        <Datagrid>
            <TextField source="id" />
            <ImageField source="image" label="" sortable={false} />
            <TextField source="name" />
            <ShowButton label=""/>
            <EditButton label=""/>
            <DeleteButton label="" className={classes.deleteButton} />
        </Datagrid>
    </List>
);

export default withStyles(styles)(CategoryList);
import React, { Component } from 'react';
import { GET_ONE } from 'react-admin';
import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
//import { CircularProgress } from '@material-ui/core';
//import get from 'lodash.get';
import {
        //TextField, 
        //ImageField,
        //ReferenceManyField,
        //ReferenceFieldController,
        LinearProgress,
        //Datagrid 
    } from 'react-admin';
        
import dataProvider from '../utils/dataProvider';

class CanvasBackgroundStyle extends Component {

  constructor(props){
    super(props)
    this.state = { loading: true };

    const { record } = this.props;
    var url = `canvas-background-textures`;
    dataProvider(GET_ONE, url, { id: record.bg_texture_id }).then((response) => {
        this.setState({ loading: false, image_url: response.data.image.image_3x.src });
    })
    .catch((e) => {
        //console.error(e);
        this.setState({ loading: false, image_url: null});
    });
  }
  
  render() {
      const { loading } = this.state;

      console.log("Loading status: " + loading);

      if (loading === true) {
          return <LinearProgress />;
      } else {
          return <div>
                    <img src={this.state.image_url} width="120" height="120" alt="" />
                 </div>;
      }
  }
}

CanvasBackgroundStyle.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    image_url: PropTypes.string
};

export default (CanvasBackgroundStyle);
// in src/users.js
import React from 'react';
import { List, 
         Datagrid, 
         TextField, 
         DateField,
         ReferenceField,
         EmailField } from 'react-admin';

const AddressList = ({ classes, ...props }) => (
    <List title="Address" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <EmailField source="email" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="phone" />
            <TextField source="street" />
            <TextField source="city" />
            <TextField source="state" />
            <TextField source="country" />
            <TextField source="zip" />
            <DateField source="created" showTime />
            <ReferenceField label="Order" source="orderId" reference="orders" allowEmpty>
                <TextField source="id" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export default (AddressList);
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import API_URL from '../config';

export default (type, params) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {

        const body = JSON.stringify({ email : params.username, password: params.password })

        const request = new Request(API_URL + '/login', {
            method: 'POST',
            credentials: 'include',
            body: body,
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })

        var response;

        return fetch(request).then(resp => {
            response = resp;
            return response.json();
        })
        .then( ( jsonResponse ) => {

            if (response.status < 200 || response.status >= 401) {
                localStorage.removeItem('username');
                localStorage.removeItem('avatar');
                localStorage.removeItem('token');
                
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                //throw new Error(jsonResponse.message);
            } else {
                localStorage.setItem('username', jsonResponse.data.email);
                localStorage.setItem('token', jsonResponse.token);

                var avatar = jsonResponse.data.avatar;
                if (avatar != null) {
                    if (avatar.length > 0) {
                        localStorage.setItem('avatar', jsonResponse.data.avatar);
                    }
                }

                document.cookie = "token=" + jsonResponse.token;
            }
        });
    }

    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('avatar');

        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        
        return Promise.resolve();
    }
    
    // called when the API returns an error
    if (type === AUTH_ERROR) {
        //console.log("AUTH_ERROR");
        
        const { status } = params;
        if (status === 401 || status === 403) {

            localStorage.removeItem('username');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('avatar');

            return Promise.reject('Failed to login, please check username and password.');
        }

        //return Promise.reject('Failed to login, please check username and passord.');
    }

    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
    }

    if (type === AUTH_GET_PERMISSIONS) {
        return Promise.resolve();
    }

    // console.log("Unknown method: " + type);
    // return Promise.reject('Unknown method');
    //return Promise.resolve();
    return Promise.reject('Unknown method');
};
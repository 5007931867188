// in src/users.js
import React from 'react';
import { List, 
         Datagrid, 
         EmailField, 
         TextField, 
         ChipField,
         DateField,
         DeleteButton,
        } from 'react-admin';

const UserList = ({ classes, ...props }) => (
    <List title="Users" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            {/*<TextField source="name" />*/}
            <TextField source="username" />            
            <EmailField source="email" />
            <TextField source="last_used_device" />
            <ChipField source="role" />
            <DateField source="created" showTime/>
            <DeleteButton label="" sortable={false} />
        </Datagrid>
    </List>
);

export default (UserList);
// in src/users.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, 
         Datagrid, 
         TextField, 
         ReferenceField,
         DeleteButton,
         EditButton,
         Filter,
         ReferenceInput,
         SelectInput
          } from 'react-admin';

import { CurrencyNumberField } from '../components/CurrencyNumberField'

const styles = {
    editButton: {
        withWidth: '20'
    },
    deleteButton: {
        withWidth: '20'
    }
};

const CustomCanvasPriceListFilter = ({ permissions, ...props }) =>
    <Filter {...props}>

        <ReferenceInput label="Size" source="size_id" reference="canvas-sizes" sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Product Type" source="product_type_id" reference="product_type">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Product Option" source="product_option_id" reference="product_type_option" link="show" allowEmpty>
            <SelectInput source="name" />
        </ReferenceInput>
        {/* <ReferenceInput label="Product Option Item" source="product_option_item_id" reference="product_type_option_items" link="show" allowEmpty>
            <SelectInput source="display_name" />
        </ReferenceInput> */}
        {/* <ReferenceInput label="Orientation" source="orientation" reference="canvas-sizes" link="show" allowEmpty>
            <SelectInput source="orientation" />
        </ReferenceInput> */}
    </Filter>;

const CustomCanvasPriceList = ({ classes, ...props }) => (
    <List title="Custom Canvas Prices" {...props} filters={<CustomCanvasPriceListFilter />} perPage={25} sort={{ field: 'surface', order: 'ASC' }}>
        <Datagrid>
            <ReferenceField label="Size" source="size_id" reference="canvas-sizes" link="show" allowEmpty>
                <TextField source="name" />
            </ReferenceField>

            <CurrencyNumberField 
                source="price" 
                options={{ 
                        style: 'currency',
                        currency: 'USD',
                }}
            />
            <CurrencyNumberField 
                source="gift_wrap" 
                options={{ 
                    style: 'currency', 
                    currency: 'USD' 
                    }}
            />

            <EditButton label="" className={classes.editButton} />
            <DeleteButton label="" className={classes.deleteButton} />
        </Datagrid>
    </List>
);

export default withStyles(styles)(CustomCanvasPriceList);
import Spellcheck from '@material-ui/icons/Spellcheck';

import { EditOrderApproval } from './EditOrderApproval';

import ApprovalList from './ApprovalList';

export default {
      list: ApprovalList,
      edit: EditOrderApproval,
      icon: Spellcheck,
}
import React from 'react';

import { Create,
        TextInput,
        SimpleForm,
        ImageInput,
        ImageField} from 'react-admin';

export const CreateBackgroundTexture = ({ permissions, ...props }) =>
<Create {...props}>
    <SimpleForm redirect="list">   
        <TextInput source="name" />
        <ImageInput source="image" label="Image" accept="image/*">
            <ImageField source="image_3x.src" title="title"/>
        </ImageInput>
    </SimpleForm>
</Create>
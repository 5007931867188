import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash.get';

import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
  });

const DownloadButton = ({ source, record = {}, elStyle }) => {
    return <a href={get(record, source)} download="">
                <IconButton variant="raised"><DownloadIcon/></IconButton>
            </a>
}

DownloadButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default withStyles(styles)(DownloadButton);
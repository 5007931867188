// in src/restClient
import { stringify } from 'query-string';
import {
    //fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    //UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';

import apiUrl from '../config';

//const apiUrl = 'http://0.0.0.0:8000'

// const API_URL = 'http://dashboard.andy.cloud:8000';

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
*/

export default (type, resource, params) => {
    
    let url = '';
    const token = localStorage.getItem('token');

    const options = { 
        headers : new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }),
    };

    // Authorization token.
    if(token) { 
      options.headers.set('Authorization', token); 
    }

    console.log("Request: " + resource + "  TYPE: " + type);
    console.log("PARMAS: " + JSON.stringify(params));

    switch (type) {
        case GET_LIST: {     
            
            var query = {};

            console.log("GET LIST: Filter");
            console.log(params.filter);

            if (Object.keys(params.filter).length !== 0) {
                query.filter = JSON.stringify(params.filter);
            }

            if (params.pagination != null) {
                const { page, perPage } = params.pagination;
                // query.range = JSON.stringify([
                //     (page - 1) * perPage,
                //     page * perPage - 1,
                // ]);
                query.range = [
                    (page - 1) * perPage,
                    page * perPage - 1,
                ];
            }

            if (params.sort != null) {
                const { field, order } = params.sort;
                query.sort = [field, order];
            };

            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case GET_ONE:
            url = `${apiUrl}/${resource}/${params.id}`;
            break;
        case GET_MANY: {
            query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            query = {
                sort: [field, order],
                range: [
                    (page - 1) * perPage,
                    page * perPage - 1,
                ],
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case UPDATE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'PUT';
            options.body = JSON.stringify(params.data);
            break;
        case CREATE:
            url = `${apiUrl}/${resource}`;
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'DELETE';
            break;
        case DELETE_MANY:
            query = {
                ids: params.ids,
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            options.method = 'DELETE';
            break;
        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    console.log("URL: " + url);

    let headers;
    var status;

    return fetch(url, options).then(res => {
            headers = res.headers;
            status = res.status;

            return res.json();
        })
        .then(json => {

            if (status === 401 || status === 403) {
                    
                localStorage.removeItem('username');
                localStorage.removeItem('avatar');
                localStorage.removeItem('token');

                return Promise.reject();

            } else if (status === 200 || status === 400) {

                if (status == 400) {
                    var error = new Error(message);
                    throw error;
                }

                if (json.status !== 'success') {
                    // throw new Error(
                    //     json.status + " \n" + json.data
                    // );
                }

                switch (type) {
                    case GET_LIST:
                    case GET_MANY_REFERENCE:
                        if (!headers.has('content-range')) {
                            console.log("Failed url: " + url);
                            throw new Error(
                                'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
                            );
                        }
                        return {
                            data: json.data.map(x => x),
                            total: parseInt(
                                headers
                                    .get('content-range')
                                    .split('/')
                                    .pop(),
                                10
                            ),
                        };
                    case GET_MANY: 
                        return json;
                    case DELETE: 
                        return json;
                    case DELETE_MANY: 
                        return json;
                    case UPDATE: 
                        return json;
                    case CREATE:
                        //console.log(JSON.stringify(json));
                        return json; //{ data: { ...params.data, id: json.id } };
                    default:
                        return { data: json.data };
                }
            } else {
                var message = json.message;
                if (message == null) { message = "Failed."; }

                var error = new Error(message);
                error.message = message;
                error.status = status;
                throw error;
            }
        });

};
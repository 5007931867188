// in src/users.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, 
         Datagrid, 
         TextField, 
         DeleteButton,
         NumberField,
         ReferenceField,
         ShowButton } from 'react-admin';

import GiftField from '../components/GiftField';

const styles = {
    cell: {
        textAlign: 'center'
    }
};

const CanvasList = ({ classes, ...props }) => (
    <List title="Canvas" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <ShowButton label=""/>
            <ReferenceField label="Order" source="orderId" reference="orders">
                <TextField source="id" style={{ textAlign: 'center' }} />
            </ReferenceField>    
            <TextField source="orientation" cellClassName={classes.cell}  style={{ textAlign: 'center' }} />
            <ReferenceField label="Size" source="size" reference="canvas-sizes">
                <TextField source="name" style={{ textAlign: 'center' }} />
            </ReferenceField>    
            <NumberField source="quantity" style={{ textAlign: 'center' }}/> 
            <NumberField source="image_count" label="Image Count" style={{ textAlign: 'center' }}/> 
            <NumberField source="image_count" label="Uploaded Images" style={{ textAlign: 'center' }}/> 
            <TextField source="material" style={{ textAlign: 'center' }}/> 
            <GiftField source="gift_wrap" label="Gift Wrapped" />
            <ReferenceField label="User" source="userId" reference="users" link="show" allowEmpty>
                <TextField source="email" style={{ textAlign: 'center' }}/>
            </ReferenceField>
            <ReferenceField label="Template" source="template_id" reference="templates" link="show" allowEmpty>
                <TextField source="name" style={{ textAlign: 'center' }}/>
            </ReferenceField>
            <DeleteButton label="" elStyle={{ minWidth: '40px', display: 'block' }}/>
        </Datagrid>
    </List>
);

export default withStyles(styles)(CanvasList);
import React from 'react';

import { Create,
         ReferenceInput,
         SelectInput,
         TextInput,
         SimpleForm } from 'react-admin';

export const CreateTemplateNotification = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">            
            <ReferenceInput label="Order Status" source="action" reference="notification-actions">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="email"  options={{ fullWidth: true }} multiline />
            <TextInput source="push_notification"  options={{ fullWidth: true }} multiline />
        </SimpleForm>
    </Create>
);
import ReceiptIcon from '@material-ui/icons/Receipt';

import { EditOrder } from './EditOrder';
import { ShowOrder } from './ShowOrder';

import OrderList from './OrderList';

export default {
      list: OrderList,
      edit: EditOrder,
      show: ShowOrder,
      icon: ReceiptIcon,
}
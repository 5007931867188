import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';

export const GiftField = ({ source, record = {}, elStyle }) => {
    if (get(record, source) === true) {
        return <img src={require('./gift-wrap.png')} width="25" height="25" alt="This item must be gift wrapped."/>
    }
    return <span style={elStyle}> </span>;
};

GiftField.propTypes = {
    addLabel: PropTypes.bool,
    elStyle: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const PureGiftField = pure(GiftField);

PureGiftField.defaultProps = {
    addLabel: true,
    elStyle: {
        display: 'block',
        margin: 'auto',
    },
};

export default PureGiftField;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import {
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
} from 'react-admin';

import SubMenu from './SubMenu';

import orders from '../orders';
import approvals from '../approvals';
import payments from '../payments';
import templates from '../templates';
import templateCategories from '../template-categories';
import steps from '../steps';
import product_type from '../product_type';
import product_type_option from '../product_type_option';
import canvas_background_textures from '../canvas_background_textures';
import customCanvasPrices from '../custom-canvas-prices';
import notificationsTemplate from '../notification-templates';
import users from '../users';
import coupons from '../coupons';
import SystemIcon from '@material-ui/icons/Settings';

class Menu extends Component {
    state = {
        menuCatalog: false,
        menuSales: false,
        menuTemplates: false,
        menuCustomers: false,
        menuSystem: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, open, logout } = this.props;

        return (
            <div>
                {' '}
                <DashboardMenuItem onClick={onMenuClick} />

                <SubMenu
                    handleToggle={() => this.handleToggle('menuSales')}
                    isOpen={this.state.menuSales}
                    sidebarIsOpen={open}
                    name="Sales"
                    icon={<orders.icon />}
                >
                    <MenuItemLink
                        to={`/orders`}
                        primaryText="Orders"
                        leftIcon={<orders.icon />}
                        onClick={onMenuClick}
                    />
                    {/* <MenuItemLink
                        to={`/canvases`}
                        primaryText="Canvases"
                        leftIcon={<canvases.icon />}
                        onClick={onMenuClick}
                    /> */}
                    {/* <MenuItemLink
                        to={`/images`}
                        primaryText="Images"
                        leftIcon={<images.icon />}
                        onClick={onMenuClick}
                    /> */}
                    {/* <MenuItemLink
                        to={`/texts`}
                        primaryText="Texts"
                        leftIcon={<texts.icon />}
                        onClick={onMenuClick}
                    /> */}
                    <MenuItemLink
                        to={`/approvals`}
                        primaryText="Approvals"
                        leftIcon={<approvals.icon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/payments`}
                        primaryText="Payments"
                        leftIcon={<payments.icon />}
                        onClick={onMenuClick}
                    />
                    {/* <MenuItemLink
                        to={`/address`}
                        primaryText="Addresses"
                        leftIcon={<addresses.icon />}
                        onClick={onMenuClick}
                    /> */}
                </SubMenu>

                <SubMenu
                    handleToggle={() => this.handleToggle('menuTemplates')}
                    isOpen={this.state.menuTemplates}
                    sidebarIsOpen={open}
                    name="Products"
                    icon={<templates.icon />}
                >

                <MenuItemLink
                        to={`/custom-canvas-prices`}
                        primaryText="Photo Canvas Prices"
                        leftIcon={<customCanvasPrices.icon />}
                        onClick={onMenuClick}
                />
                
                <MenuItemLink
                        to={`/templates-all`}
                        primaryText="Templates"
                        leftIcon={<templates.icon />}
                        onClick={onMenuClick}
                />
                {/* <MenuItemLink
                        to={`/template-prices`}
                        primaryText="Template Pricings"
                        leftIcon={<templatePrices.icon />}
                        onClick={onMenuClick}
                /> */}
                <MenuItemLink
                        to={`/steps`}
                        primaryText="Template Steps"
                        leftIcon={<steps.icon />}
                        onClick={onMenuClick}
                />
                <MenuItemLink
                        to={`/template-categories`}
                        primaryText="Categories"
                        leftIcon={<templateCategories.icon />}
                        onClick={onMenuClick}
                />
                <MenuItemLink
                        to={`/product_type`}
                        primaryText="Product Types"
                        leftIcon={<product_type.icon />}
                        onClick={onMenuClick}
                />
                <MenuItemLink
                        to={`/product_type_option`}
                        primaryText="Product Options"
                        leftIcon={<product_type_option.icon />}
                        onClick={onMenuClick}
                />
                <MenuItemLink
                        to={`/canvas-background-textures`}
                        primaryText="Canvas Textures"
                        leftIcon={<canvas_background_textures.icon />}
                        onClick={onMenuClick}
                />
{/* 
                <MenuItemLink
                        to={`/materials`}
                        primaryText="Materials"
                        leftIcon={<materials.icon />}
                        onClick={onMenuClick}
                /> */}

                </SubMenu>

                <MenuItemLink
                        to={`/users`}
                        primaryText='Users'
                        leftIcon={<users.icon />}
                        onClick={onMenuClick}
                />  

                <MenuItemLink
                        to={`/coupons`}
                        primaryText='Coupons'
                        leftIcon={<coupons.icon />}
                        onClick={onMenuClick}
                />  

                <SubMenu
                    handleToggle={() => this.handleToggle('menuSystem')}
                    isOpen={this.state.menuSystem}
                    sidebarIsOpen={open}
                    name="System"
                    icon={<SystemIcon />}
                >
                    <MenuItemLink
                        to={`/notification-templates`}
                        primaryText='Status Notifications'
                        leftIcon={<notificationsTemplate.icon />}
                        onClick={onMenuClick}
                    />
                </SubMenu>

                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    )
);

export default enhance(Menu);
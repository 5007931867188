var API_URL = process.env.REACT_APP_API_URL; //'https://api.oc-canvas.com';

// var isDev = process.argv[process.argv.length - 1];
// if (isDev === '--dev') {
//   API_URL = 'https://dev-api.oc-canvas.com';
//   console.log("This instance is running locally!");
// }

//process.env.REACT_APP_API_URL = API_URL;

module.exports = API_URL;

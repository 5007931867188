// in src/users.js
import React from 'react';
import { List, 
         Datagrid, 
         EditButton,
         TextField } from 'react-admin';

const EditProductTypeOption = ({ classes, ...props }) => (
    <List title="Product Type Options" {...props} perPage={25}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            {/* <TextField source="properties" /> */}
            <EditButton label="" sortable={false} />
        </Datagrid>
    </List>
);

export default (EditProductTypeOption);
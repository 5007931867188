import UserIcon from '@material-ui/icons/Group';

import UserList from './UserList';

import { CreateUser } from './CreateUser';

export default {
      list: UserList,
      create: CreateUser,
      icon: UserIcon,
}
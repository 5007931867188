// in src/posts.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//import PrintShippingLabelButton from '../components/PrintLabelButton'
import ReversedBooleanField from '../components/ReversedBooleanField'
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DeleteButton,
    DateField,
    ChipField,
    ShowButton,
    EditButton,
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput
} from 'react-admin';

const styles = {
    deleteButton: {
        minWidth: '40px', 
        display: 'block'
    }
};

const OrderFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Order Status" source="status" reference="orderstatuses" sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const OrderList = ({ classes, ...props }) => (
    <List title="Orders" {...props} perPage={20} sort={{ field: 'id', order: 'DESC' }} filters={<OrderFilter />}>
        <Datagrid>
            <TextField source="id" />
            <ShowButton label="" />
            <TextField source="total" />
            <ReferenceField label="User" source="userId" reference="users" link="show" allowEmpty>
                <TextField source="email" />
            </ReferenceField>
            <DateField source="created" showTime />
            <ReferenceField label="Shipping method" source="shipping_method_id" reference="shipping" link="false" allowEmpty>
                <ChipField source="label" />
            </ReferenceField>
            <ReversedBooleanField source="acceptingUploads" label="Uploaded" />
            <ReferenceField label="Status" source="status" reference="orderstatuses" link="false" allowEmpty>
                <ChipField source="name" />
            </ReferenceField>
            <EditButton label=""/>
            <DeleteButton label="" className={classes.deleteButton} />
        </Datagrid>
    </List>
);

export default withStyles(styles)(OrderList);